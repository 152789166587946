export default function ProjectImage() {
    return (
        <div style = {{
            border: '1px solid',
            borderRadius: '5px',
            height: '100px',
            width: '200px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            Image(s) of Project Here
        </div>
    )
}